<template>
    <div>
        <b-table :fields="fields" :items="agents" empty-text="No buying agents at this time">
            <template #cell(name)="data">
                <b-link @click="get_agent(data.item)">
                    <b-icon-cursor></b-icon-cursor>
                    {{data.item.name}}
                </b-link>
            </template>
            <template #cell(wallet)="data">
                {{std_fig(data.item.wallet.balance)}}
            </template>
            <template #cell(action)="data">
                <b-button @click="()=>{$bvModal.show('credit-agent'); agent = data.item }" variant="link" size="sm" class="text-primary text-decoration-none">
                    <b-icon-plus class="pb-1"></b-icon-plus>
                    Credit Agent
                </b-button>
                &nbsp;
                <b-button @click="()=>{$bvModal.show('debit-agent'); agent = data.item }" variant="link" size="sm" class="text-danger text-decoration-none">
                    <span class="pb-1">&minus;</span>
                    Debit Agent
                </b-button>
            </template>
        </b-table>
        <credit-agent :agent="agent" @credit-agent="credit_agent"></credit-agent>
        <debit-agent :agent="agent" @debit-agent="debit_agent"></debit-agent>
    </div>
</template>
<script>
import {axiosApiInstance} from "@/plugins/axios"
import { Wallet } from '../accounting';
import CreditAgent from './CreditAgent.vue';
import { std_fig } from '..';
import DebitAgent from './DebitAgent.vue';
import { AgentMixin } from './mixins';
/**
 * @typedef {Object} Agent
 * @property {string} id
 * @property {Wallet} wallet
 * @property {string} name
 * @property {string} username
 * @property {phone_no} phone_no
 */
export default {
  components: { CreditAgent, DebitAgent },
  mixins:[AgentMixin],
    data(){
        return {
            agent:{name:""},
            fields:[
                {key:"name",thClass:"text-center",tdClass:"text-center"},
                {key:"username",label:"Email",thClass:"text-center",tdClass:"text-center"},
                {key:"phone_no",label:"Phone No",thClass:"text-center",tdClass:"text-center"},
                {key:"wallet",label:"Balance",thClass:"text-center",tdClass:"text-center"},
                {key:"action",thClass:"text-center",tdClass:"text-center"}
            ],
            agents:[]
        }
    },
    mounted(){
        this.list_agents();
    },
    methods:{
        async list_agents(){
            let res = await axiosApiInstance({
                url:process.env.VUE_APP_BACKEND+"/buyers"+"/"
            });
            this.agents = res.data.map(el=>{
                el.wallet = new Wallet(el.wallet.id,el.wallet.balance); 
                return el;
            });
        },
        /**
         * @param {Agent} agent
         */
        get_agent(agent){
            this.$store.commit("save_agent",agent);
            this.$nextTick(()=>{
                this.$router.push({path:'./buyers/'+agent.id})
            })
        },
        std_fig
    }
}
</script>